import React, { useEffect, useState } from 'react'
import Publish from '../Index_n_publish/Publish'
import { useLocation, useParams } from 'react-router-dom'
import volume from '../data_json/data'
import data1 from '../data_json/data.json'
import axios from 'axios';


//import file of different papers

function Articles({props}) {


    const [isLoading, setIsLoading] = useState(true);
    const [data, setData]=useState(null)
    const {vol, issue, id}=useParams()
    
    useEffect(()=>{
        for (let record of data1.Volume){
            if (record.volume==vol){
                for(let issu in record.Issues){
                    if (record.Issues[issu].issue==issue){
                        for (let pape=0; pape< record.Issues[issu].paper.length; pape++){
                            if(record.Issues[issu].paper[pape].id==id){
                                setData(record.Issues[issu].paper[pape]); 
                                setIsLoading(false);   
                            }
                        }
                    }
                }
            }
        }
        
    },[vol,issue,id]);

   


    


  return (
    <div className="container">
    
    {isLoading ? (
        
        <div className="spinner-border text-danger" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
        

    ):(
        <div className="container" key={data.id}>
        <div className="row">
            <div className="col-sm-8 mb-3 mb-sm-0">

                <div className="card border-0">
                <div className="card-body" key={data.id}>
                    <h1 className="card-title">{data.title}</h1>
                    <h5 className="card-text"><p className="text-success"><strong>Publisher: IJSDIA</strong></p></h5>
                    <a
                    href={data.file}
                    download={`${data.title}.pdf`}
                    className="btn btn-danger"
                    target="_blank"
                    rel="noreferrer"
                    >
                    PDF <i className="bi bi-download"></i>
                    </a>

                    <p className="card-text my-3">
                        {data.author.map((q,index)=>(
                            <span className="mx-2" key={index}><strong>{q}</strong> ;</span>
                            
                        ))}    
                    </p>
                    <p className="card-text" style={{textAlign:"justify"}}><strong>Abstract:-</strong>{data.abstract}</p>

                    <p className="card-text"><span className='fw-bold'>Published in: </span><span className="text-success">International Journal of Secure Digital Information Age</span></p>

                    <div className="card-title"> <h5> <strong>Citation:</strong> </h5></div>

                    <div className="card-body">
                        <p className="card-text">
                            <span className="author">
                            {data.author.slice(0,2).map((q,index)=>(
                                <span className="mx-2" key={index}>{q};</span>
                            
                            ))} et al.   
                            </span>
                            <span className="title"> {data.title}, </span>
                            <span className="journal">International Journal of Secure Digital Information Age,</span>
                            <span className="volume"> vol. {vol} </span>
                            <span className="issue">no. {issue}, </span>
                            <span className="pages">pp. {data.pages}, </span>
                            <span className="year">{data.year} </span>
                        </p>

                    </div>
    
                </div>
                </div>
            </div>

            <div className="col-sm-4">
                <Publish />
            
            </div>
        </div>

        </div>

    ) }
    </div>
    
  )
}

export default Articles