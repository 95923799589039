import React from 'react'
import Publish from '../Index_n_publish/Publish'

function Contacts() {
  return (
    <div className="container">
    <div className="row">
        <div className="col-sm-8 mb-3 mb-sm-0">

            <div className="card border-0">
            <div className="card-body">
                <h2 className="card-title">Contact the Journal</h2>

                <hr className="bg-danger border-2 border-top border-dark" />

                <div className="card border-0 w-100 mb-3 my-4">
                <div className="card-body rounded-3">

                    <ul>
                        <li className="my-3">
                            <h3 className="card-title">Author Service enquiries, Submission-related enquiries</h3>
                            <p className="card-text">For any general pre-submission queries, including e.g. article types, indexing, timeline, publishing costs and APCs of the journal, please visit</p>
                            <p className="card-text">Email :- editor@ijsdia.in</p>
                            <h5 className="card-title">Editor in Chief</h5>

                        </li>

                        {/* <li className="my-5">
                            <h3 className="card-title">Submission-related enquiries</h3>
                            <p className="card-text">Queries about submission issues, peer review process, or the status of your manuscript should be sent to <strong>Anusha Gudisa</strong> </p>
                            <p className="card-text">Phone :-  +91 9560402070</p>
                            <p className="card-text">Email :- ijsdia@gmail.com</p>

                        </li>

                        <li className="my-5">
                            <h3 className="card-title">Production-related enquiries</h3>
                            <p className="card-text">Queries about accepted manuscripts in production or post-publication corrections should be sent to <strong> Jeyapradha saravanan</strong></p>
                            <p className="card-text">Phone :-  +91 1234567890</p>
                            <p className="card-text">Email :- abcd@gmail.com</p>

                        </li>

                        <li className="my-5">
                            <h3 className="card-title">Rights and Permissions enquiries</h3>
                            <p className="card-text">For permission requests to reuse or reprint content, please follow the link ‘Rights and permissions’ on the relevant article page. For other queries, contact</p>
                            <p className="card-text">Phone :-  +91 1234567890</p>
                            <p className="card-text">Email :- abcd@gmail.com</p>

                        </li>

                        <li className="my-5">
                            <h3 className="card-title">Publication-related enquiries</h3>
                            <p className="card-text">Queries related to journal publishing should be sent to <strong> Teena Bedi</strong></p>
                            <p className="card-text">Phone :-  +91 1234567890</p>
                            <p className="card-text">Email :- abcd@gmail.com</p>

                        </li> */}
                    </ul>
                    
                    


                    
                </div>
                </div>
            </div>
            </div>


           


        </div>

        <div className="col-sm-4">
            <Publish />
           
        </div>
    </div>

    </div>
  )
}

export default Contacts