import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar/Navbar';
import Nav from './components/Nav/Nav';
import Poster from './components/Poster/Poster';
import Nav2 from './components/Nav2/Nav2';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Overview from './components/OverView/Overview';
import Editors from './components/EditorialLeaderBoard/Editors';
import Footer from './components/Footer/Footer';
import Update from './components/Journal_update/Update';
import Ethics from './components/Ethics/Ethics';
import Scope from './components/Aim_n_scope/Scope';
import Guideline from './components/Submission_guideline/Guideline';
import Issues from './components/Volume_n_Issues/Issues';
import Author_inst from './components/Author_Instruction/Author_inst';
import Contacts from './components/Contacts/Contacts';
import Publish_inst from './components/publish_inst/Publish_inst';
import Articles from './components/Articles/Articles';


function App() {
  return (
    <>
    <BrowserRouter>
        {/* <Navbar />
        <Nav /> */}

          <Poster />
          <Nav2 />
        

        <Routes>
          <Route path="" Component={Overview} />
          <Route path="Editor" Component={Editors} />
          <Route path="Update" Component={Update} />
          <Route path="Ethics" Component={Ethics} />
          <Route path="Aim_n_scope" element={<Scope/>} />
          <Route path="Guidelines" element={ <Guideline/> } />
          <Route path="volume_and_issues" element={<Issues/>} />
          <Route path="Author_inst" element={ <Author_inst/> } />
          <Route path="contacts" element={<Contacts/> } />
          <Route path="publish_instruction" element={ <Publish_inst/> } />
          <Route path="articles/:vol/:issue/:id" element={<Articles/>} />
          
        </Routes>

        <Footer />

    </BrowserRouter>
    </>

    
  )
}

export default App;
