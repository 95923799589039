import React from 'react'
import Cover from '../Images/Cover1.png'

function Poster() {
  return (
    
    <div className="card">
        <div className="card-header">
        International Journal of Secure Digital
                         Information Age (Online)
        </div>
        <div className="card-body container">
        <div className="row">
                <div className="col-sm-3 mb-3 mb-sm-3">
                    <div className="card border-0">
                    <div className="card-body">
                        <img src={Cover} alt="" height="220px" />
                    </div>
                    </div>
                </div>
                <div className="col-sm-9">
                    <div className="card border-0 ">
                    <div className="card-body">
                        <h1><a href="/" className="card-title">International Journal of Secure Digital Information Age</a></h1>
                        <h4 className="card-text my-3">ISSN : 0975 - 1823</h4>
                        <h6 className="card-text">Publish Mode</h6>
                        <h6 className="card-text">Hybrid</h6>
                        <a href="https://docs.google.com/forms/d/e/1FAIpQLSfuwTA3IKk2KlZhk80waa7cM3YkvRNWLJyj9i9LhobcUm1DQQ/viewform?vc=0&c=0&w=1&flr=0&usp=mail_form_link" className="btn btn-outline-custom my-3" target="_blank">Submit Your Manuscript</a>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    
  
  )
}

export default Poster