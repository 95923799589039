import React from 'react'

function Footer() {
  return (
    
        <footer className="text-center text-lg-start bg-body-tertiary text-muted">
        
        
        <div className="text-center p-4" style={{background: "rgba(0, 0, 0, 0.05)"}}>
            © 2024 Copyright:
            <a className="text-reset fw-bold" href="/"> IJSDIA</a>
        </div>
        
        </footer>

  )
}

export default Footer