import React from 'react'
import Publish from '../Index_n_publish/Publish'
import Cover from '../Images/Cover1.png'
import { Link } from 'react-router-dom'

function Overview() {
  return (

    <div className="container">
    <div className="row">
        <div className="col-sm-8 mb-3 mb-sm-0">

            <div className="card border-0">
            <div className="card-body">
                <h2 className="card-title">OverView</h2>

                <div className="card border-0 w-100 mb-3 my-4">
                <div className="card-body rounded-3" style={{background:"rgb(255,243,243)"}}>
                    
                    <p className="card-text" style={{textAlign:'justify'}} >International Journal of Secure Digital Information Age is a platform for the dissemination of original, scientific, theoretical or applied research in the fields of Computer Sciences and allied fields.</p>

                    <ul style={{textAlign:'justify'}}>
                        <li>Publishes original research in the numerous fields of Computer Science and Information Technology.</li>
                        <li>Features empirical models, formal techniques, and applications in varied domains of Computer Science.</li>
                        {/* <li>An official publication of School of Computer and System Sciences, Jawaharlal Nehru University (JNU), New Delhi.</li> */}
                        <li>Aims to bridge the gap between research and practice by promoting industry-relevant research.</li>
                        <li>Provides a platform for strong empirical component in research.</li>
                        <li>The journal is indexed on Scopus.</li>
                    </ul>

                    <hr className="bg-danger border-2 border-top border-dark" />

                    <h4 className="card-text">Editor-in-Chief</h4>
                    <p className="card-text">Dr. Karan Singh</p>

                    <hr className="bg-danger border-2 border-top border-dark" />
                    
                    
                </div>
                </div>
            </div>
            </div>


            <div className="row">
                    <div className="col-sm-2 mb-2 mb-sm-0">
                        <div className="card border-0">
                        <div className="card-body">
                            <img src={Cover} alt="" style={{height:"100px"}} />
                        </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="card border-0">
                        <div className="card-body">
                            <h4 className="card-title">Latest Issue</h4>
                            <h5 className="card-text">March 2024 <div className="vr"></div> Volumn 16, Issue 3</h5>
                            <Link to="/volume_and_issues" className="btn btn-outline-custom">View All Volumes and Issues</Link>
                        </div>
                        </div>
                    </div>
            </div>


        </div>

        <div className="col-sm-4">
            <Publish />
           
        </div>
    </div>

    </div>
  )
}

export default Overview