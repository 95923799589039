import React from 'react'
import Publish from '../Index_n_publish/Publish'
import {Link} from 'react-router-dom'
import format from '../Author_Instruction/format.doc'

function Publish_inst() {
  return (
    <div className="container">
    <div className="row">
        <div className="col-sm-8 mb-3 mb-sm-0">

            <div className="card border-0">
            <div className="card-body">
                <h2 className="card-title">How to Publish with Us</h2>

                <hr className="bg-danger border-2 border-top border-dark" />

                <div className="card border-0 w-100 mb-3 my-4">
                <div className="card-body rounded-3">
                    
                    <p className="card-text" style={{textAlign:'justify'}} >International Journal of Secure Digital Information Age is a platform for the dissemination of original, scientific, theoretical or applied research in the fields of Computer Sciences and allied fields.</p>

                    <ul style={{textAlign:'justify'}}>
                        <li className="my-3">
                            <h5 className="card-title">Step 1</h5>
                            <p className="card-text">Prepare Your Manuscript according to Author's Guidelines</p>
                            <p className="card-text">Click the below link to download the sample format of the paper.</p>
                            <a href={format} className="btn btn-outline-custom" download='ijsdia_format' target='_blank' rel='noreferrer'>Click to Download the Sample</a>
                        </li>
                        <li className="my-3">
                            <h5 className="card-title">Step 2</h5>
                            <p className="card-text">Submit your paper in the below link</p>
                            <a href="https://docs.google.com/forms/d/e/1FAIpQLSfuwTA3IKk2KlZhk80waa7cM3YkvRNWLJyj9i9LhobcUm1DQQ/viewform?vc=0&c=0&w=1&flr=0&usp=mail_form_link" className="btn btn-outline-custom" target="_blank">Submit Your Manuscript</a>
                        </li>

                        <li className="my-3">
                            <h5 className="card-title">Step 3</h5>
                            <p className="card-text">Receive Your Submission Acknowledgement</p>
                        </li>
                        <li className="my-3">
                            <h5 className="card-title">Step 4</h5>
                            <p className="card-text">Report an Issue</p>
                        </li>
                        
                    </ul>

                </div>
                </div>
            </div>
            </div>


           


        </div>

        <div className="col-sm-4">
            <Publish />
           
        </div>
    </div>

    </div>
  )
}

export default Publish_inst