import React from 'react'
import Publish from '../Index_n_publish/Publish'

function Scope() {

  const scope =[
    {
      id: 1,
      Chapter : "Applied Cryptography",
      content:[
        "Encryption and Decryption techniques",
        "Probability, information, complexity, number theory and fields",
        "Mathematical models for security and Attacks",
        "Cryptographic stream generation schemes",
        "Key Generation, Distribution, management and Security Mechanism Schemes",
        "Digital signature schemes",
        "Hash Generation schemes",
        "Patents and Cryptography",
        "Other relevant topics to Applied Cryptography"
      ]
    },

    {
      id: 2,
      Chapter: "Computer and Information Security",
      content:[
        "Computer Hardware and Software Security",
        "Computer Attacks",
        "Computer Attack Detection and Security Mechanism",
        "Firewalls",
        "Security issue in Email, DNS, Web",
        "Security Issues in Distributed and Content- delivery networks",
        "Security issues in Sensor Network * Security Issues in Cellular, Mobile Network, Satiate network",
        "Security issues in Routing Protocols",
        "Security Architecture (PKI, RAS, FPKI, SET, WLAN/802.11)",
        "Computer Identification, Authorization and Access control",
        "Other relevant issues about computer network security",
      ]
    },
    {
      id: 3,
      Chapter: "Network Security",
      content:[
        "Security issues in LAN, MAN, WAN, PAN",
        "Security issue in Email, DNS, Web",
        "Security Issues in Distributed and Content- delivery networks",
        "Security issues in Sensor Network",
        "Security Issues in Cellular,",
        "Mobile Network, Satiate network",
        "Security issues in Routing Protocols ",
        "Security Architecture (PKI, RAS, FPKI, SET, WLAN/802.11)",
        "Other relevant issues about computer network security Computer and Network Forensic",
      ]
    },
    {
      id: 4,
      Chapter: "Computer Forensic Evidence",
      content:[
        "Computer Forensic Evidence Management, Transmission and Analysis",
        "Computer and Network Forensic Tools",
        "Network Intrusion Detection, Analysis, Prevention",
        "Network Auditing",
        "Cyber Forensic",
        "Biometrics Recognitions, authentication and Technologies",
        "Image Processing",
        "Security in Multimedia Application",
        "Security issue in Real Time System",
        "Security in E-commerce, IC card, RFID Application",
        "Other relevant topics to computer and network forensic",
      ]
    },

    {
      id: 5,
      Chapter: "Cyber Security and Blockchain",
      content:[
        "Cyber Threat Intelligence, Transmission and Analysis",
        "Identity and Access Management",
        "Cybersecurity Governance and Risk Management",
        "Digital Forensics and Incident Response",
        "Cyber security in Critical Infrastructure",

        "Consensus Mechanism",
        "Scalability Solutions",
        "Privacy and Security",
        "Blockchain in Supply Chain Management",
        " Blockchain in Healthcare",
        "Blockchain in Internet of Things (IoT)",
        " Blockchain in Real Estate"
      ]
    },

    {
      id: 6,
      Chapter: "AI Based Security",
      content:[
        "Cyber Security",
        "Web Security",
        "Android Security",
        "Fuzzy Logic Based Information",
        "Security Systems",
        "Honey Pot",
        "Cyber Forencics",
        "Vulneribility Assesment",
        "Attacks and Defenses"
      ]
    }

   

  ]

  
  return (
    <div className="container">
    <div className="row">
        <div className="col-sm-8 mb-3 mb-sm-0">

            <div className="card border-0">
            <div className="card-body">
                <h1 className="card-title">Aim and Scope</h1>

                <div className="card border-0 w-100 mb-3 my-4">
                <div className="card-body rounded-3">
                    
                    <h3 className="card-title">a peer reviewed, quarterly research journal</h3>
                    <h3 className="card-title">Aim and Scope:</h3>

                    <p className="card-text" style={{textAlign:"justify"}}>The IJSDIA seeks papers describing significant and quality contributions in the security field and invites submissions on Applied Cryptography, Computer and Network Security, Information security, Computer and Network forensics etc. Technical topics include:</p>

                    <ul style={{textAlign:'justify'}}>
                      {scope.map((e,index)=>(
                        <li key={e.id}>
                          <h5 className="card-title">{e.Chapter}</h5>
                          <ul style={{textAlign:"justify"}}>

                            {e.content.map((w,ind)=>(
                              <li className="card-text" key={ind}>{w}</li>

                            ))}

                          </ul>
                        </li>

                      ))}
                        
                    </ul>
                </div>
                </div>
            </div>
            </div>



        </div>

        <div className="col-sm-4">
            <Publish />
           
        </div>
    </div>

    </div>
  )
}

export default Scope