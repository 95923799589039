import React from 'react'
import Publish from '../Index_n_publish/Publish';

function Ethics() {
  return (
    <div className="container">
    <div className="row">
        <div className="col-sm-8 mb-3 mb-sm-0">

            <div className="card border-0">
            <div className="card-body">
                <h1 className="card-title">Ethics & disclosures</h1>
                <hr className="bg-danger border-2 border-top border-dark" />

                <div className="card border-0 w-100 mb-3 my-4">
                <div className="card-body rounded-3">
                    
                    <h5 className="card-text my-3" style={{textAlign:'justify'}} >The journal is committed to maintaining the highest level of integrity in the content published.</h5>

                    <h5 className="card-text my-3" style={{textAlign:'justify'}} >This journal has a Conflict of Interest policy in place and complies with international, national and/or institutional standards on research involving Human Participants and/or Animals and Informed Consent.</h5>

                    <h5 className="card-text my-3" style={{textAlign:'justify'}} >The journal is a member of the Committee on Publication Ethics (COPE) and subscribes to its principles on how to deal with acts of misconduct thereby committing to investigate allegations of misconduct in order to ensure the integrity of research.</h5>

                    <h5 className="card-text my-3" style={{textAlign:'justify'}} >The journal may use plagiarism detection software to screen the submissions. If plagiarism is identified, the (COPE) guidelines on plagiarism will be followed.</h5>

                    <h5 className="card-text my-3" style={{textAlign:'justify'}} >Content published in this journal is peer reviewed (Double Blind).</h5>

                </div>
                </div>
            </div>
            </div>

        </div>

        <div className="col-sm-4">
            <Publish />
           
        </div>
    </div>

    </div>
  )
}

export default Ethics