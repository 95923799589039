import React from 'react'
import { Link } from 'react-router-dom'

function Nav2() {
  return (
    <ul className="nav justify-content-center my-3">
            <li className="nav-item mx-3">
                <Link to="/Editor" className="btn btn-outline-custom" aria-current="page">Editorial Board</Link>
            </li>
            <li className="nav-item mx-3">
                <Link to="/Aim_n_scope" className="btn btn-outline-custom">Aims and Scope</Link>
            </li>
            <li className="nav-item mx-3">
                <Link to="/Update" className="btn btn-outline-custom">Journal Updates</Link>
            </li>
    </ul>
  )
}

export default Nav2