import React from 'react'
import img1 from '../Images/language-checker-medium.png'
import { Link } from 'react-router-dom'

function Publish() {
  return (
    <div className="card border-0">
    <div className="card-body">
        <h2 className="card-title">For Authors</h2>
        <hr className="bg-danger border-2 border-top border-dark" />

        <div className="card my-4" style={{"width": "17rem"}}>
        <ul className="list-group list-group-flush">
            <li className="list-group-item"><Link to="/Guidelines" className="btn btn-outline-custom">Submission Guideline</Link></li>
            <li className="list-group-item"><Link to="/Author_inst" className="btn btn-outline-custom" href="">Author Instruction</Link></li>
            <li className="list-group-item"><Link to="/Ethics" className="btn btn-outline-custom">Ethics and Disclouser</Link></li>
            <li className="list-group-item"><Link to="/publish_instruction" className="btn btn-outline-custom">How to Publish with Us</Link></li>
            {/* <li className="list-group-item"><a className="btn btn-outline-custom" href="">Open Access Fees and Funding</a></li> */}
            <li className="list-group-item"><Link to="/contacts" className="btn btn-outline-custom" href="">Contact the Journal</Link></li>
        </ul>
        </div>

        <div className="card" style={{width: "17rem"}}>
            <img src={img1} className="card-img-top" alt="..."/>
            <div className="card-body">
              <h5 className="card-title">Language Quality Checker</h5>
              <p className="card-text" style={{textAlign:"justify"}}>Some quick example text to build on the card title and make up the bulk of the card's content.</p>
              <a href="#" className="btn btn-outline-custom">Get your Manuscript Editied for Free</a>
            </div>
        </div>

        <hr className="bg-danger border-2 border-top border-dark" />

        <h3 className="card-title">Explore</h3>

        <div className="card" style={{"width": "16rem"}}>
            <ul className="list-group list-group-flush">
              <li className="list-group-item"> <Link to="/articles" className="btn btn-outline-custom">Articles</Link></li>
              <li className="list-group-item"> <Link to="/volume_and_issues" className="btn btn-outline-custom">Volumes and Issues</Link></li>
              
            </ul>
        </div>

    </div>
    </div>
  )
}

export default Publish