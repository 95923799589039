import {React,useState} from 'react'
import './Nav.css'

function Nav() {

  const [active, setActive]= useState(false);
  const [active1, setActive1]= useState(false);
  const [active2, setActive2]= useState(false);
  
  const activ=()=>{

    setActive(true);
    setActive1(false);
    setActive2(false);

  }
  const activ1=()=>{

    setActive(false);
    setActive1(true);
    setActive2(false);

  }
  const activ2=()=>{
    setActive(false);
    setActive1(false);
    setActive2(true);

  }

  return (
    <ul className="nav nav-pills border-bottom mx-3 my-3">
        <li className="nav-item">
            <a className={`btn btn-outline-custom ${active?"active":''} mx-2`} onClick={activ} href="#" >Find a Journal</a>
        </li>
        <li className="nav-item">
            <a className={`btn btn-outline-custom ${active1?"active":''} mx-2 `} onClick={activ1} href="#">Publish With Us</a>
        </li>
        <li className="nav-item">
            <a className={`btn btn-outline-custom ${active2?"active":''} mx-2`} onClick={activ2} href="#">Track Your Research</a>
        </li>
        
    </ul>
  )
}

export default Nav