import React from 'react'
import Publish from '../Index_n_publish/Publish'

function Guideline() {

 
  return (
    <div className="container">
    <div className="row">
        <div className="col-sm-8 mb-3 mb-sm-0">

            <div className="card border-0">
            <div className="card-body">
                <h1 className="card-title">Submission Guidelines</h1>

                <div className="card border-0 w-100 mb-3 my-4">
                <div className="card-body rounded-3" style={{background:"rgb(255,243,243)"}}>
                    
                    <p className="card-text" style={{textAlign:'justify'}} >International Journal of Secure Digital Information Age is a platform for the dissemination of original, scientific, theoretical or applied research in the fields of Computer Sciences and allied fields.</p>
                    
                    <ul className="card-text" style={{textAlign: "justify"}}>
                        <li className="card-text">Contributions shall be prepared according to the following instructions. All
                        accepted manuscripts are edited for adherence to Journal format and style,
                        clarity, syntax, and punctuation. Authors must transfer copyright in writing to the
                        publisher when an article is accepted.</li>

                        <li>The manuscript should submit through e-mail on the following email addresses
                        editor@ijsdia.in. Final manuscript should be send with
                        copyright form via scan of copyright and author’s information with dually
                        signature at same email address.</li>

                        <li>Form 1: Paper format for authors. (Attached with mail)<br/>

                        Form 2: Copyright form (Attached with mail)</li>
                    </ul>
                    

                    <hr className="bg-danger border-2 border-top border-dark" />

                    <h4 className="card-text">Editor-in-Chief</h4>
                    <p className="card-text">Dr. Karan Singh</p>

                    
                </div>
                </div>
            </div>
            </div>


            


        </div>

        <div className="col-sm-4">
            <Publish />
           
        </div>
    </div>

    </div>
  )
}

export default Guideline