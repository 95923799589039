import React from 'react'
import Publish from '../Index_n_publish/Publish'



function Update() {

  const issues=[
    "International Journal of Information Technology Welcomes Special Issues",
    "Guidelines for Special Issues"
  ];


  return (
    <div className="container">

    <div className="row">
        <div className="col-sm-8 mb-3 mb-sm-0">
            <div className="card my-2 border-0">
            <div className="card-body">
              <h1 className="card-title">Journal Updates</h1>

              <hr className="bg-danger border-2 border-top border-dark" />

              {issues.map((e,index)=>(

                <div className="alert alert-info" key={index}>
                    <strong>{e}</strong>
                </div>

              ))}

            </div>
            </div>
        </div>

        <div className="col-sm-4">
            <Publish />
        </div>
    </div>

    </div>
  )
}

export default Update