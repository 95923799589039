import React, {useEffect, useState, useMemo} from 'react'
import Publish from '../Index_n_publish/Publish'
import { Link } from 'react-router-dom'
import Articles from '../Articles/Articles'
// import paper18 from '../CRC/vol_1_issue_1/Analysis_of_SMS-based_Plain_and_Encrypted_Text_Messages.pdf'
// import paper17 from '../CRC/vol_1_issue_1/Prevention_of_Web_attacks_using_Hirschberg_Algorithm.pdf'
// import paper16 from '../CRC/vol_1_issue_1/Efficient_Group_Key_Generation_in_Non-homogeneous_Mobile_Ad_Hoc_Networks.pdf'
// import paper15 from '../CRC/vol_1_issue_1/Bayesian_Belief_Networks_with_Genetic_Local_Search_for_Detecting_Network_Intrusions.pdf'
// import axios from 'axios'
import  Volume from '../data_json/data.json' 




function Issues() {
    // const Volume =[
    //     {
    //         volume: 1,
    //         Issues : [
                
    //             {
    //                 issue: 1,
    //                 date: "June 2016",
    //                 paper:[
    //                     {
    //                         id:1,
    //                         title:"Combinatorial Properties of Permutation Polynomials over some Finite Rings Zn",
    //                         author: [
    //                             "SHANKAR B.R.",
    //                             " VADIRAJA BHATTA G.R"
    //                         ],
    //                         pages: "1-5",
    //                         file: {paper15},
    //                         abstract: "The network traffic data provided for the design of intrusion detection always are large with ineffective information and enclose limited and ambiguous information about users’ activities. Many statistical methods rely on numerical optimization to estimate a model’s parameters. Unfortunately, conventional algorithms sometimes fail. Even when they do converge, there is no assurance that they have found the global, rather than a local, optimum. We study the problems and propose a two phases approach in our intrusion detection design. In the first phase, we develop a genetic algorithm based feature selection algorithm in combination with local search method to remove the worthless information from the original high dimensional database. Next, we design an intrusion detection method to solve the problems of uncertainty caused by limited and ambiguous information, using Bayesian belief networks. In the experiments, we choose DARPA KDD99 intrusion detection data set as our evaluation tools. The results obtained describes that our feature selection algorithm and intrusion detection method perform well in terms of accuracy and low false alarm rate. "
    //                     },
    
    //                     {
    //                         id:2,
    //                         title:"Threshold Signature Cryptography Scheme in Wireless Ad-hoc Computing",
    //                         author: [
    //                             "SANDIP VIJAY",
    //                             "S. C. SHARMA"
    //                         ],
    //                         pages: "6-11",
    //                         file: {paper15},
    //                         abstract: "The network traffic data provided for the design of intrusion detection always are large with ineffective information and enclose limited and ambiguous information about users’ activities. Many statistical methods rely on numerical optimization to estimate a model’s parameters. Unfortunately, conventional algorithms sometimes fail. Even when they do converge, there is no assurance that they have found the global, rather than a local, optimum. We study the problems and propose a two phases approach in our intrusion detection design. In the first phase, we develop a genetic algorithm based feature selection algorithm in combination with local search method to remove the worthless information from the original high dimensional database. Next, we design an intrusion detection method to solve the problems of uncertainty caused by limited and ambiguous information, using Bayesian belief networks. In the experiments, we choose DARPA KDD99 intrusion detection data set as our evaluation tools. The results obtained describes that our feature selection algorithm and intrusion detection method perform well in terms of accuracy and low false alarm rate. "
    //                     },
    
    //                     {
    //                         id:3,
    //                         title:"A New Approach for Identification Scheme of LFSR-based Pseudorandom Generators",
    //                         author:[
    //                             "SUCHETA CHAKRABARTI",
    //                             "S. K. PAL"
    //                         ],
    //                         pages: "12-19",
    //                         file: {paper15},
    //                         abstract: "The network traffic data provided for the design of intrusion detection always are large with ineffective information and enclose limited and ambiguous information about users’ activities. Many statistical methods rely on numerical optimization to estimate a model’s parameters. Unfortunately, conventional algorithms sometimes fail. Even when they do converge, there is no assurance that they have found the global, rather than a local, optimum. We study the problems and propose a two phases approach in our intrusion detection design. In the first phase, we develop a genetic algorithm based feature selection algorithm in combination with local search method to remove the worthless information from the original high dimensional database. Next, we design an intrusion detection method to solve the problems of uncertainty caused by limited and ambiguous information, using Bayesian belief networks. In the experiments, we choose DARPA KDD99 intrusion detection data set as our evaluation tools. The results obtained describes that our feature selection algorithm and intrusion detection method perform well in terms of accuracy and low false alarm rate. "
    //                     },
    
    //                     {
    //                         id:4,
    //                         title:"Real Coded Genetic Algorithm based Digital Stereo Image Watermarking",
    //                         author:[
    //                             "SANJEEV KUMAR",
    //                             " BALASUBRAMANIAN RAMAN",
    //                             " MANOJ THAKUR"
    //                         ],
    //                         pages: "20-29",
    //                         file: {paper15},
    //                         abstract: "The network traffic data provided for the design of intrusion detection always are large with ineffective information and enclose limited and ambiguous information about users’ activities. Many statistical methods rely on numerical optimization to estimate a model’s parameters. Unfortunately, conventional algorithms sometimes fail. Even when they do converge, there is no assurance that they have found the global, rather than a local, optimum. We study the problems and propose a two phases approach in our intrusion detection design. In the first phase, we develop a genetic algorithm based feature selection algorithm in combination with local search method to remove the worthless information from the original high dimensional database. Next, we design an intrusion detection method to solve the problems of uncertainty caused by limited and ambiguous information, using Bayesian belief networks. In the experiments, we choose DARPA KDD99 intrusion detection data set as our evaluation tools. The results obtained describes that our feature selection algorithm and intrusion detection method perform well in terms of accuracy and low false alarm rate. "
    //                     },
    
    //                     {
    //                         id:5,
    //                         title:"Bayesian Belief Networks with Genetic Local Search for Detecting Network Intrusions",
    //                         author: [
    //                             "MRUTYUNJAYA PANDA",
    //                             "MANAS RANJAN PATRA"
    //                         ],
    //                         pages: "30-37",
    //                         file: {paper15},
    //                         abstract: "The network traffic data provided for the design of intrusion detection always are large with ineffective information and enclose limited and ambiguous information about users’ activities. Many statistical methods rely on numerical optimization to estimate a model’s parameters. Unfortunately, conventional algorithms sometimes fail. Even when they do converge, there is no assurance that they have found the global, rather than a local, optimum. We study the problems and propose a two phases approach in our intrusion detection design. In the first phase, we develop a genetic algorithm based feature selection algorithm in combination with local search method to remove the worthless information from the original high dimensional database. Next, we design an intrusion detection method to solve the problems of uncertainty caused by limited and ambiguous information, using Bayesian belief networks. In the experiments, we choose DARPA KDD99 intrusion detection data set as our evaluation tools. The results obtained describes that our feature selection algorithm and intrusion detection method perform well in terms of accuracy and low false alarm rate. "
    //                     },
    
    //                     {
    //                         id:6,
    //                         title:"Efficient Group Key Generation in Non-homogeneous Mobile Ad Hoc Networks",
    //                         author:[
    //                             "VIJAY GHORPADE",
    //                             "YASHWANT JOSHI",
    //                             "RAMCHANDRA MANTHALKAR"
    //                         ],
    //                         pages: "38-44",
    //                         file: {paper16},
    //                         abstract: "Mobile Ad hoc Networks (MANET) are emerging as the desired environment for an increasing number of commercial and military applications. Security on the other hand, is now an indispensable requirement for these applications. However, the limitations of the dynamic, infrastructure-less nature of MANET impose major difficulties in establishing a secure framework suitable for such services. The design of efficient key management (KM) schemes for MANET is of paramount importance. MANET create additional challenges for implementing the Group Key ( K G) establishment due to resource constraints on nodes and dynamic changes on the topology. To facilitate the deployment of K Gin MANET, a range of contributory and distributed algorithms have been proposed. Tree-based Group DiffieHellman (TGDH) is one of the efficient group key agreement protocols to generate K G. However, TGDH assumes all members to have an equal computing power. In distributed computing, nodes are heterogeneous and members do not necessarily possess homogeneous computing power. In this paper an algorithm is proposed which assigns the critical task of modular exponentiation to nodes having more computing power that is helpful in Efficient Group Key Generation. Performance analysis shows that the proposed algorithm reduces the number of modular exponentiation operations from O(nlog n) to O(n) . In addition to that the memory required at each node is reduced from O(n) to O(log n) . As a result the overall efficiency of group key generation improves significantly. "
    //                     },
    
    //                     {
    //                         id:7,
    //                         title:"Prevention of Web attacks using Hirschberg Algorithm",
    //                         author:[
    //                             "EZUMALAI R",
    //                             " AGHILA G"
    //                         ],
    //                         pages: "45-50",
    //                         file: {paper17},
    //                         abstract: " The major problem faced by the web application is the parameter manipulation, through which the attackers are aiming to access the database. Generally web applications maintain same structure and value. In that, required information is being accessed by the identical variables and keywords through web parameters. Parameter manipulation is the major issue in the web application used by the attacker to manipulate the parameter being sent by the browser and executed by the server. In this paper, we propose new, highly automated approach for protecting existing Web applications against parameter attacks. Our Proposed system has to extract keywords from the web application parameter and use the gene alignment approach to measure the identity between two parameter sequences in order to avoid web attacks. It uses an approach called Hirschberg algorithm, divide and conquer approach for measuring the identity between two sequences and it has advantageous over other methods in order to reduce the time O((nm)) and space complexity O(min(nm)). This system was able to stop all of the successful attacks and did not generate any false positives. "
    
    //                     },
    
    //                     {
    //                         id:8,
    //                         title:"Analysis of SMS-based Plain and Encrypted Text Messages",
    //                         author:[
    //                             "SAIBAL K. PAL",
    //                             "SUMITRA"
    //                         ],
    //                         pages: "51-58",
    //                         file: {paper18},
    //                         abstract:" SMS (Short Message Service) text messaging has become a widely used service for communication. The style used for creation of SMS messages is informal and the associated language has poor grammatical structure. For automated processing and interpretation by machines, SMS messages have to be thoroughly analyzed without any human intervention. The first step undertaken in this direction is creation of lookup tables for SMS text corresponding to the English text. Based on this, automated conversion of SMS into English and vice versa is carried out. For its further interpretation, thorough linguistic analysis of SMS messages is attempted which includes frequency analysis, diagraph analysis, special case analysis and VCS (Vowel-Consonant-Symbol) analysis. Analysis with blanks removed from the text is also done. Using these approaches, basic schemes for cryptanalysis of SMS based messages are reported."
    //                     }
    
    
    //                 ]
    //             },

    //             {
    //                 issue: 2,
    //                 date: "December 2016",
    //                 paper:[
    //                     {
    //                         id:1,
    //                         title:"Threshold Signature Cryptography Scheme in Wireless Ad-hoc Computing",
    //                         author:[
    //                             "SHANKAR B.R.",
    //                             "VADIRAJA BHATTA G.R."
    //                         ],
    //                         pages: "6-11"
    //                     },
    //                     {
    //                         id:2,
    //                         title:"Threshold Signature Cryptography Scheme in Wireless Ad-hoc Computing",
    //                         author:[
    //                             "SHANKAR B.R.",
    //                             "VADIRAJA BHATTA G.R."
    //                         ],
    //                         pages: "6-11"
    //                     },
    //                     {
    //                         id:3,
    //                         title:"Threshold Signature Cryptography Scheme in Wireless Ad-hoc Computing",
    //                         author:[
    //                             "SHANKAR B.R.",
    //                             "VADIRAJA BHATTA G.R."
    //                         ],
    //                         pages: "6-11"
    //                     }
    
    //                 ]
    //             },
    
                
    
    //         ]S
    //     },
    
    // ]

  return (

    <div className="container">
    <div className="row">
        <div className="col-sm-8 mb-3 mb-sm-0">

            <div className="card border-0">
            <div className="card-body">
                <h2 className="card-title">Volumes and Issues</h2>

                <div className="card border-0 w-100 mb-3 my-4">
                <div className="card-body rounded-3" style={{background:"rgb(255,243,243)"}}>

                    {Volume.Volume && Volume.Volume.map((e,index)=>(

                        <div className="container" key={index}>
                        <p className="gap-1" >
                        <a className="btn btn-primary" data-bs-toggle="collapse" href={`#collapseExample${index}`} role="button" aria-expanded="false" aria-controls={`collapseExample${index}`}>
                            Volume {e.volume}
                        </a>
                        </p>
                        <div className="collapse" id={`collapseExample${index}`}>
                        <div className="card card-body">

                            {e.Issues && e.Issues.map((w,ind)=>(
                                <div className="card-text" key={ind}>
                                
                                    <h5 className="d-inline-flex gap-1">
                                        <a className="icon-link icon-link-hover" style={{'--bs-link-hover-color-rgb': '25, 135, 84'}} data-bs-toggle="collapse" href={`#collapseExample-${ind}-${index}`} role="button" aria-expanded="false" aria-controls={`collapseExample-${ind}-${index}`}>
                                            <h5 className="card-title"> Issue {w.issue} | {w.date}</h5>
                                            
                                        </a>
                                    </h5>
                                        <div className="collapse" id={`collapseExample-${ind}-${index}`}>
                                        <div className="card card-body">

                                            {w.paper.reverse().map((q,inde)=>(

                                            
                                            <div className="container" key={inde}>
                                                <hr className="bg-danger border-2 border-top border-dark" />
                                                    <h4><Link to={`/articles/${e.volume}/${w.issue}/${q.id}`} state={q} className="link-body-emphasis link-offset-2 link-underline-opacity-25 link-underline-opacity-75-hover">{q.title}</Link></h4>
                                                    <p className="card-title">{q.author[0]} & {q.author[1]}</p>
                                                    <p className="card-title">Original Research | Date | pages: {q.pages} </p>
                                                <hr className="bg-danger border-2 border-top border-dark" />
                                            </div>

                                            ))}
                                        </div>
                                        </div>
                            </div>


                            ))}

                            
                            </div>
                        </div>
                        </div>
                      
                    ))}                       
                </div>
                </div>
            </div>
            </div>



        </div>

        <div className="col-sm-4">
            <Publish />
           
        </div>
    </div>

    </div>
   
  )
}

export default Issues