import React from 'react'
import Publish from '../Index_n_publish/Publish'
import format from './format.doc';

function Author_inst() {

  const guideline =[
    {
        title:"General information for authors",
        content:[
            
                "The paper should be written in English only.",
                "No paper that is published earlier or under consideration elsewhere be submitted.",
                "If the paper is already or is going to be presented in any conference in identical form or with some changes, it should be informed at the time of paper submission.",
                "Once the paper has selected, authors are required to submit a statement stating that the said paper hasn’t published or submitted elsewhere.",
                "Once the paper has been selected for publication, it becomes the property of the publishers and all the copyrights will be transferred to the publisher.",
                "Paper will be reviewed by 2/3 expert reviewers of the relevant field. The paper will be selected on the basis of their recommendations only."          
            
        ]
    },
    {
        title:"Manuscript Description",
        content:[
            
                "The manuscript should be typewritten in 10-point font, double spaced with a margin of 1 inch on all sides. The length of paper should not increase beyond 20 pages including figures and tables. First page includes the title of the paper, authors’ name, their affiliations, addresses and e-mail addresses. Rest of the paper shouldn’t bear anything that divulges the identification of the authors. The paper should clearly mention the methods and results of the work. Authors are advised to avoid large and irrelevant discussions."
                               
        ]
    },
    {
        title:"Abstract",
        content:[
            
                "An abstract of less than 200-300 words is required with each manuscript stating the significance of the proposed work and its results in concise form. 5 to 8 keywords should be provided at the end of the abstract."         
            
        ]
    },
    {
        title:"Manuscript main part",
        content:[
            
                "Articles, submitted to International Journal Secure Digital Information Age should be no longer than 20 double-spaced type written pages, including double-spaced end notes (or no more than 5,000 words overall). A brief statement should mansion after reference section such as the author’s present affiliation, publishing career, research interests and recent photo. DO NOT indicate authors’ names on manuscript pages. DO NOT reveal authors’ identity through references in the text or in any other way.",           
            
        ]
    },
    {
        title:"Tables and figures",
        content:[
            
                "They should be numbered using Arabic numerals (1,2…..).Only necessary tables and figures should be given. For more details use the given format",           
            
        ]
    },
    {
        title:"References",
        content:[
            
                "References should be cited in the text by the surname of the author(s) and the year. In case of more than two authors, surname of the first author followed by et al. and the year should be cited in the text.",
                "In the list of references at the end of the paper, complete references should be given in the following style."
                
                               
        ]
    }
  ]
  return (
    <div className="container">
    <div className="row">
        <div className="col-sm-8 mb-3 mb-sm-0">

            <div className="card border-0">
            <div className="card-body">
                <h1 className="card-title">Author Instruction</h1>

                <div className="card border-0 w-100 mb-3 my-4">
                <div className="card-body rounded-3" style={{background:"rgb(255,243,243)"}}>
                    
                    <p className="card-text" style={{textAlign:'justify'}} >International Journal of Information Technology is a platform for the dissemination of original, scientific, theoretical or applied research in the fields of Computer Sciences and allied fields.</p>
                    
                    <ul style={{textAlign:'justify'}}>
                    {guideline.map((e,index)=>(

                        <li className="card-text">
                            <h5 className="card-text">{e.title}</h5>

                            <ul>
                                {e.content.map((w,inde)=>(
                                    <li className="card-text">{w}</li>

                                ))}
                                
                            </ul>

                        </li>

                    ))}
                    </ul>

                    

                    <p className="card-text">The link below contains a file which serves as a sample paper for comprehending the format. This sample paper is designed to aid in understanding the structure, layout, and content expected in similar documents. Accessing this file can provide valuable insights into the presentation style, organization, and key elements required for creating documents of a similar nature.</p>
                    
                    <a href={format} className="btn btn-outline-custom" download='ijsdia_format' target='_blank' rel='noreferrer'>Click to Download the Sample</a>
                </div>
                </div>
            </div>
            </div>


            


        </div>

        <div className="col-sm-4">
            <Publish />
           
        </div>
    </div>

    </div>
  )
}

export default Author_inst